import React, { useEffect, useState } from "react";
import {
    Paper,
    Box,
    Divider,
    Typography,
} from "@mui/material";
import history from "../../common/history";
import { addFavourite, removeFavourite } from "../CvOverview/CVOverviewActions"
import { favoriteUsers } from "./FavoritesActions";
import { updateProgressLine } from "../../components/HeaderMenu/HeaderActions";

import cookie from "js-cookie";
import { connect } from "react-redux";

import CVOverviewUserCard from "../../components/CVOverviewUserCard/CVOverviewUserCard";
import FavoritesSkeleton from "./FavoritesSkeleton";

const styles = {
    typographyStyleHeading: {
        color: "black",
        textAlign: "center",
        marginTop: "1%",
       
    },
    containerUsersPaperStyle: {
        borderRadius: "10px",
        marginTop: "1%",
        marginBottom: "1%",
        maxWidth: "1200px",
        marginLeft: "15%",
        marginRight: "15%",
        padding: "0.25em",
    },
};

const filterInitialazation = {
    searchText: "",
    yos: "",
    grade: "",
    faculty: [],
    permanentResidenceCity: [],
    temporaryResidenceCity: [],
    permanentResidenceCountry: [],
    temporaryResidenceCountry: [],
    cv: false,
    favourite: true,
    dob: "",
    pagePagination: 1,
    stepPagination: 50,
}

const Favorites = (props) => {
    const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
    //const companyID = cookie.get("id");
    const [progress, setProgress] = useState(true);

    useEffect(() => {
        props.updateProgressLine(true);
        props.favoriteUsers({ filters: filterInitialazation, companyID: companyID })
            .then((data) => { /*console.log(props.FavoriteUsersList);*/ setProgress(false); });
    }, []);

    useEffect(() => {
        if (progress === false)
            document.title = `Favorites | CVBook`;
    }, [progress]);

    const handleFavourited = (isFavourited, userID) => {
        let payload = {
            userID: userID,
            kompanijaID: companyID,
        };
        //console.log(isFavourited);
        if (isFavourited) {
            props.addFavourite(payload).then((data) => {
                //console.log(data);
            });
        } else {
            props.removeFavourite(payload).then((data) => {
                //console.log(data);
            });
        }
    };

    return (
        <Box>
            <Box sx={{maxWidth: '1200px',  marginLeft: "15%", marginRight: "15%",}}>
            <Typography variant="h2" sx={styles.typographyStyleHeading}>Favorites</Typography>
            </Box>
            {!progress && (
                <Paper variant="outlined" sx={styles.containerUsersPaperStyle}>
                    {props.FavoriteUsersList &&
                        props.FavoriteUsersList.lista.map((user, index) => {
                            return (
                                <React.Fragment key={"ReactFragmentIndex-" + index}>
                                    {index !== 0 && <Divider />}
                                    <CVOverviewUserCard
                                        handleFavourited={(isFavourited, userID) =>
                                            handleFavourited(isFavourited, userID)
                                        }
                                        handleClick={() => {
                                            //console.log(user.userID);
                                            history.push(`/user/${user.userID}`, {
                                                detail: user.userID,
                                            });
                                        }}
                                        user={user}
                                    />
                                </React.Fragment>
                            );
                        })}
                </Paper>
            )}
            {progress && (<FavoritesSkeleton progress={progress} />)}
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        FavoriteUsersList: state.favoriteList.favoriteUsers,
    };
};
export default connect(mapStateToProps, {
    favoriteUsers,
    addFavourite,
    removeFavourite,
    updateProgressLine,
})(Favorites);