import React, { useEffect, useState } from "react";
import { Typography, Box, IconButton, Tooltip } from "@mui/material";
import LetterAvatar from "lettered-avatar";
import JsFileDownloader from 'js-file-downloader';

import CVOverviewUserCardSkeleton from "./CVOverviewUserCardSkeleton";

import DownloadIcon from "@mui/icons-material/Download";
import LaunchIcon from "@mui/icons-material/Launch";
import StarIcon from "@mui/icons-material/Star";

const styles = {
  containerStyle: {
    margin: "1%",
    display: "flex",
    justifyContent: "space-between",
    padding: "0.1em",
  },
  contentBox: {
    display: "flex",
    flexGrow: 1,
    "&:hover": {
      cursor: "pointer",
    },
  },
  middleContentBox: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'right',
    marginRight: "20%",
    alignItems: 'center',
  },
  avatarWrapperBox: {
    margin: "0.5em",
  },
  nameAndSurnameBox: {
    textAlign: "start",
    paddingTop: "0.7em",
  },
  nameTypography: {
    fontWeight: "bold",
  },
  actionBox: {
    display: "flex",
    paddingTop: "1em",
    marginRight: "1em",
  },
  dividerStyle: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginLeft: "1.5em",
    marginRight: "1em",
  },
  favouritesButtonActive: {
    color: "gold",
  },
  favouritesButtonInactive: {},
  downloadCVButtonStyle: {
    '&:hover': {
      color: 'primary.main'
    }
  }
};

const CVOverviewUserCard = (props) => {
  const [isFavourited, setIsFavourited] = useState(
    props.user.kompanija_favourites &&
      props.user.kompanija_favourites.length > 0
      ? true
      : false
  );

  useEffect(() => {
    if(props.user)
    setIsFavourited(props.user.kompanija_favourites &&
      props.user.kompanija_favourites.length > 0
      ? true
      : false)
  }, [props.user]);

  const downloadCV = () => {
    let fileType = props.user.cv.cv.endsWith(".pdf") ? ".pdf" : (props.user.cv.cv.endsWith(".doc") ? ".doc" : ".docx")
    new JsFileDownloader({
      url: props.user.cv.cv,
      filename: `${props.user.licniPodaci.ime}_${props.user.licniPodaci.prezime}_CV${fileType}`,
    })
      .then(() => {
        console.log("Download succes");
      })
      .catch((error) => {
        console.log("ERROR" + error);
      });
  }

  function datumAzuriranja(stariDatum) {
    const date = new Date(stariDatum);
    const newDate =
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) +
      "." +
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) +
      "." +
      date.getFullYear() +
      ".";
    return newDate;
  }

  return (
    <div>
      {!props.progress && (
        <Box sx={styles.containerStyle} key={props.user.userID}>
          <Box onClick={props.handleClick} sx={styles.contentBox}>
            <Box sx={styles.avatarWrapperBox}>
              <LetterAvatar
                name={props.user.licniPodaci ? (props.user.licniPodaci.ime +
                  " " +
                  props.user.licniPodaci.prezime) : ""}
                imgSrc={
                  props.user.licniPodaci && props.user.licniPodaci.profilnaSlika
                    ? //"https://dev.jobfairnis.rs/users/returnpicture/?file=" +
                    props.user.licniPodaci.profilnaSlika
                    : null
                }
                options={{
                  twoLetter: true,
                  size: 52,
                }}
              />
            </Box>
            <Box sx={styles.nameAndSurnameBox}>
              <Typography sx={styles.nameTypography} variant="body1">
                {props.user.licniPodaci ? (props.user.licniPodaci.ime +
                  " " +
                  props.user.licniPodaci.prezime) : ""}
              </Typography>
              <Typography variant="body2">
                {props.user.fakultets[0] ? props.user.fakultets[0].naziv : ""}
              </Typography>
            </Box>
          </Box>
          <Box onClick={props.handleClick} sx={styles.middleContentBox}>
            {props.middleColum === "dateUpdated" && (
              <Typography variant="body1">
                {props.user.licniPodaci ? datumAzuriranja(props.user.licniPodaci.datumAzuriranja) : ""}
              </Typography>
            )}
            {(props.middleColum === "yearOfStudy" && props.user.studiras.length > 0) && (
              <Typography variant="body1">
                {props.user.studiras ? props.user.studiras[0].godineStudija : ""}
              </Typography>
            )}
            {(props.middleColum === "grade" && props.user.studiras.length > 0) && (
              <Typography variant="body1">
                {props.user.studiras ? props.user.studiras[0].prosek : ""}
              </Typography>
            )}
          </Box>
          <Box sx={styles.actionBox}>
            {props.user.cv && (
              <Box>
                <Tooltip title="Download CV">
                  <IconButton sx={styles.downloadCVButtonStyle} onClick={() => downloadCV()}>  {/* disabled={props.user.cv?false:true} */}
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {!props.user.cv && (
              <Box sx={{ width: "40px" }}>
                <IconButton sx={styles.downloadCVButtonStyle}>  {/* disabled={props.user.cv?false:true} */}
                </IconButton>
              </Box>
            )}
            <Box>
              <Tooltip title="Open">
                <IconButton onClick={props.handleClick}>
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Favourite">
                <IconButton
                  sx={
                    isFavourited
                      ? styles.favouritesButtonActive
                      : styles.favouritesButtonInactive
                  }
                  onClick={() => {
                    setIsFavourited(!isFavourited);
                    props.handleFavourited(!isFavourited, props.user.userID);
                  }}
                >
                  <StarIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      )}
      {props.progress && (<CVOverviewUserCardSkeleton user={props.user} />)}
    </div>
  );
};
export default CVOverviewUserCard;
