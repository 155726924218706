import './App.css';
import React, { useEffect, useState } from 'react';
import { Router, Route, Switch, useLocation } from 'react-router-dom';
//import { connect, useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import cookie from 'js-cookie'
import history from './common/history';

import Login from './pages/Login/login';
import Dashboard from './pages/Dashboard/Dashboard';
import CvOverview from './pages/CvOverview/CvOverview';
import UserPage from './pages/UserPage/UserPage';
import History from './pages/History/History';
import Favorites from './pages/Favorites/Favorites';
import HeaderMenu from './components/HeaderMenu/HeaderMenu';
import FAQ from "./pages/FAQ/FAQ";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/core';

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

const Lato = "'Lato', sans-serif";
const theme = createTheme({
  palette: {
    background: {
      default: '#f3f2ef',
      paper: 'white'
    },
    primary: {
      main: "#1b75bc",
      contrastText: '#fff',
      backgroudColor: 'rgb(242, 245, 249)'
    },
    secondary: {
      main: '#2C2CC7',
      contrastText: '#fff',
    },
    error: {
      main: "#FF5815",
      contrastText: '#fff',
    },
    warning: {
      main: '#FFC415',
      contrastText: '#fff',
    },
    success: {
      main: '#10C477',
      contrastText: '#fff',
    },
    grey10: { main: '#fafaf9', },
    grey20: { main: '#f3f2ef', },
    grey30: { main: '#e9e5df', },
    grey40: { main: '#d6cec2', },
    grey50: { main: '#b9af9f', },
    grey60: { main: '#958b7b', },
    grey70: { main: '#6e6558', },
    grey80: { main: '#474139', },
    grey90: { main: '#211f1c', },
  },
  typography: {
    fontFamily: Lato,
  },
});

const useStyles = makeStyles({});

function App(props) {
  useStyles();
  const [validToken, setValidToken] = useState(null);

  useEffect(() => {
    //const token = cookie.get('token');
    const token = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).token:undefined;
    setValidToken(token)

    if (token && (token !== "undefined" || token !== "null")) {
    }
    else {
      // This is temporary hardcoded, auth guard needs improvements on this part
      // if (history.location.pathname !== '/register' && history.location.pathname !== '/login') {
      //   history.push('/login')
      // }
    }
    return () => {
    }
  }, [validToken])

  const logout = () => {
    cookie.remove('CV-Book', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // cookie.remove('token', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // cookie.remove('id', { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // cookie.remove('username',{ domain: process.env.REACT_APP_COOKIE_DOMAIN })
    setValidToken(undefined)
  }

  const isAuthenticated = () => {
    if (validToken) {
      return (
        <div className={"App"}>
          <HeaderMenu childrenComponents={renderContent()} logout={() => logout()} />
        </div>
      )
    }
    else {
      // if (history.location.pathname !== '/login' && history.location.pathname !== '/register') history.replace('/login')
      return (
        <Switch>
          <Route path="/login">
            <Login onSuccessfulLogin={token => {
              setValidToken(token);
              history.push('/');
            }} />
          </Route>
          <Redirect to="/login" />
        </Switch>
      )
    }
  }

  const renderContent = () => {
    return (
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/overview" component={CvOverview} />
        <Route path="/user/:id" component={UserPage} />
        <Route path="/faq" component={FAQ} />
        <Route path="/history" component={History} />
        <Route path="/favorites" component={Favorites} />
        <Redirect to="/" />
      </Switch>
    )
  }

  return (
    <ThemeProvider theme={theme}>
      <Router history={history}>
        <ScrollToTop />
        {validToken !== null && isAuthenticated()}
      </Router >
    </ThemeProvider>
  );
};
export default App;