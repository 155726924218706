import AxiosInstance from '../../axiosService/axiosService';

export const fetchCharts = () => async dispatch => {
    await AxiosInstance.post(`/kompanija/stats/`).then(res => {
        //console.log(res.data);
        dispatch({ type: 'FETCH_CHARTS', payload: res.data });
    }).catch(err => {
        console.log('Dashboard fetch chatrs error', err);
    });
}

