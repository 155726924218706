const initialState = { users: [], filterdUsers: [] };

const CVOverviewReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_USERS': {
            return Object.assign({}, state, { ...state, users: action.payload })
        }
        case 'FILTER_USERS': {
            return Object.assign({}, state, { ...state, filterdUsers: action.payload })
        }
        case 'FILTER_OPTIONS': {
            return Object.assign({}, state, { ...state, filterOptions: action.payload })
        }
        default: return state;
    }
}
export default CVOverviewReducer;