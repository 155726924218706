import axiosService from '../../axiosService/axiosService'
import cookie from 'js-cookie'

export async function login(credentials) {

  try {
    const res = await axiosService.post('kompanija/auth', credentials);

    const { data } = res;

    //This is shit code because the server we made initially is shit 
    if (data.status && data.status === 409) throw res;
    if (data.status && data.status > 301) throw res;

    let newCookie = {
      token: res.data[0].token,
      id: res.data[0].kompanijaID,
      username: res.data[0].username,
    };

    cookie.set('CV-Book', JSON.stringify(newCookie), { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // cookie.set('token', res.data[0].token, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // cookie.set('id', res.data[0].kompanijaID, { domain: process.env.REACT_APP_COOKIE_DOMAIN });
    // cookie.set('username', res.data[0].username, { domain: process.env.REACT_APP_COOKIE_DOMAIN });

    return Promise.resolve(data);
  }
  catch (error) {
    console.log("LOGIN ERROR", error);
    const {data} = error;
    
    if (data && data.status === 409) return Promise.reject("LOGIN_WRONG_PASSWORD")

    return Promise.reject("LOGIN_FAILED");
  }
}