import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" fontSize={12} textAnchor="middle" dominantBaseline="central">
      {`${(percent * 100).toFixed(1)}%`}
      
    </text>
  );
};
// const renderLegend = (props) => {
//   const  payload  = props.data;

//   return (
//     <ul>
//       {
//         payload.map((entry, index) => (
//           <li key={`item-${index}`}>{entry.value}</li>
//         )),
//         <li>Godine studija korisnika</li>
//       }
//     </ul>
//   );
// }

const StudentPerYearPie = (props, data) => {
  //console.log(props.data, 'DATA')
  const colors = ["#3C8CC5", "#1b75bc", "#085D9E", "#064779"];
  const reformData = (data) => {
    let newData = data;
    newData = [newData[0], newData[1], newData[2], newData[3]] = [newData[3], newData[2], newData[1], newData[0]];

    return newData;
}


  return (
    <ResponsiveContainer width="100%" height="90%">
      <PieChart width={400} height={400}>
        <Pie
          data={props.data ? reformData(props.data) : []}
          cx="50%"
          cy="50%"
          labelLine={false}
          label={renderCustomizedLabel}
          outerRadius={80}
          innerRadius={40}
          nameKey = "godineStudija"
          fill="#8884d8"
          dataKey="broj"
          isAnimationActive = {false}
          
        >
          {props.data ? reformData(props.data).map((entry, index) => (
            <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
          )):<></>}
        </Pie>
        <Tooltip />
        <Legend />
        
      </PieChart>
    </ResponsiveContainer>
  );
};

export default StudentPerYearPie;