import {
  Button,
  CircularProgress,
  TextField,
  Typography,
  Box,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { login } from "./loginActions";
import JF22OriginalniLogo from "../../assets/JF22OriginalniLogo.png";
import PersonIcon from '@mui/icons-material/Person';
import LockIcon from '@material-ui/icons/Lock';

const styles = {
  background: {
    background: 'linear-gradient(rgb(71, 122, 176) 41%, rgb(24, 49, 130) 100%)',
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundSize: "contain",
  },
  mainContainer: {
    backgroundColor: "white",
    maxWidth: "500px",
    maxHeight: "600px",
    display: "flex",
    flexDirection: "column",
    padding: "50px",
    borderRadius: "10px",
  },
  logoBest: {
    maxWidth: 250,
    maxHeight: 125,
  },
  logoJF: {
    maxWidth: 300,
    maxHeight: 150,
    paddingTop: "24px",
    paddingBottom: "24px",
  },
  element: {
    marginBottom: "15px",
  },
  error: {
    color: "red",
    textAlign: 'center',
    marginTop: '10px'
  },
  inputField: {
    marginBottom: "15px",
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  marginLeft: {
    marginLeft: "10px",
    width: "100%",
  },
  iconStyle: {
    color: '#1b75bc',
  },
  spinner: {
    width: '100%',
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px'
  },
  dialogActionsStyle: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dialogContainer: {
    display: "flex",
    justifyContent: "center",
    flexDirection: 'column',
    padding: '30px'
  },
  localizationDialogDiv: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'left',
  },
  localizationDiv: {
    position: 'fixed',
    bottom: '0px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '10px',
    paddingBottom: '5px',
    border: 'solid',
    borderWidth: '1px',
    borderColor: "#1b75bc",
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  flagStyle: {
    height: "32px",
    margin: "0.5em",
    cursor: "pointer",
    justifyContent: 'left',
  },
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: '20px'
  },
  iconContainer: {
    margin: "6px",
  },
};

const Login = (props) => {
  const [data, setData] = useState({
    username: "",
    password: "",
  });
  const [spinner, setSpinner] = useState(false);
  const [error, setError] = useState(null);

  const handleKeyPress = useCallback(event => {
    if (event.keyCode === 13) {
      submit();
    }
  }, [data])

  useEffect(() => {
    window.addEventListener('keypress', handleKeyPress)
    return () => {
      window.removeEventListener('keypress', handleKeyPress)
    }
  }, [handleKeyPress])

  const submit = () => {
    setSpinner(true);
    setError("");
    login(data)
      .then((res) => {
        props.onSuccessfulLogin(res[0].token);
      }) 
      .catch((error) => {
        setError('err');
      })
      .finally(() => setSpinner(false));
  };

  return (
    <Box sx={styles.background}>
      <Box sx={styles.mainContainer}>
        <Box sx={styles.logoContainer}>
          <img src={JF22OriginalniLogo} width="300" height="103" className="JFLogoImg" alt="JFLogoImg" />
        </Box>
        <Box sx={styles.inputField}>
          <Box sx={styles.iconContainer}>
            <PersonIcon style={{ fill: "#1b75bc" }}></PersonIcon>
          </Box>
          <TextField
            sx={styles.marginLeft}
            placeholder={"Username"}
            value={data.email}
            onChange={(e) => setData({ ...data, username: e.target.value })}
            fullWidth
          />
        </Box>
        <Box sx={styles.inputField}>
          <Box sx={styles.iconContainer}>
            <LockIcon style={{ fill: "#1b75bc" }}></LockIcon>
          </Box>
          <TextField
            type={'password'}
            sx={styles.marginLeft}
            placeholder='Password'
            value={data.password}
            onChange={(e) => setData({ ...data, password: e.target.value })}
            fullWidth
          />
        </Box>
        {!spinner ? (
          <Button
            sx={styles.element}
            variant={"contained"}
            color="primary"
            style={{
              borderRadius: "10px",
              backgroundColor: "#1b75bc",
              color: "white",
            }}
            onClick={() => submit()}
          >
            Ulogujte se
          </Button>
        ) : ( 
          <Box sx={styles.spinner}>
            <CircularProgress style={{color: "#1b75bc"}} />
          </Box>
        )}

        {error && <Typography variant={'body1'} sx={styles.error}>{error}</Typography>}
      </Box>
    </Box>
  );
};

export default Login;