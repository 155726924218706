import AxiosInstance from '../../axiosService/axiosService';

export const fetchUsers = (kompanijaID) => async dispatch => {
    await AxiosInstance.post(`users`, { kompanijaID }).then(res => {
        dispatch({ type: 'FETCH_USERS', payload: res.data });
    }).catch(err => {
        console.log('CV overview fetch users  error', err);
    });
}

export const filterUsers = (payload) => async dispatch => {
    await AxiosInstance.post(`users/newFilter`, payload).then(res => {
        //console.log(res.data);
        dispatch({ type: 'FILTER_USERS', payload: res.data });
        dispatch({ type: 'UPDATE_PROGRESS_LINE', payload: false });
    }).catch(err => {
        console.log('CV overview filter users  error', err);
    });
}

export const addFavourite = (payload) => async dispatch => {
    await AxiosInstance.post(`kompanija/favourites/add`, payload).then(res => {
    }).catch(err => {
        console.log('Add favourite error', err);
    });
}

export const removeFavourite = (payload) => async dispatch => {
    await AxiosInstance.post(`kompanija/favourites/remove`, payload).then(res => {
    }).catch(err => {
        console.log('Remove favourite  error', err);
    });
}

export const getFilters = () => async dispatch => {
    await AxiosInstance.post(`kompanija/formOptions`, {}).then(res => {
        //console.log("payload");
        //console.log(res.data);
        dispatch({ type: 'FILTER_OPTIONS', payload: res.data });
    }).catch(err => {
        console.log('CV overview filter options  error', err);
    });
}