import AxiosInstance from '../../axiosService/axiosService';



export const fetchUserById = (userID, kompanijaID) => async dispatch => {
    await AxiosInstance.post(`users/getbyid`, { "userID": userID, "kompanijaID": kompanijaID} ).then(res => { 
        //console.log(res.data);
        dispatch({ type: 'FETCH_USER_BY_ID', payload: res.data });
    }).catch(err => {
        console.log('Fetch user by ID error', err);
    });
}

export const addFavourite = (payload) => async dispatch => {
    //console.log("payload");
    //console.log(payload);
    await AxiosInstance.post(`kompanija/favourites/add`, payload).then(res => {
        //console.log(res.data);
    }).catch(err => {
        console.log('Add favourite error', err);
    });
}

export const removeFavourite = (payload) => async dispatch => {
    await AxiosInstance.post(`kompanija/favourites/remove`, payload).then(res => {
        //console.log(res.data);
    }).catch(err => {
        console.log('Remove favourite  error', err);
    });
}

export const addToHistory = (payload) => async dispatch => {
    //console.log("payload");
    //console.log(payload);
    await AxiosInstance.post(`kompanija/history/add`, payload).then(res => {
        //console.log(res.data);
    }).catch(err => {
        console.log('Add to history error', err);
    });
}