import React from "react";
import {
    Paper,
    Divider,
    Box,
} from "@mui/material";
import HistoryUserCard from "../../components/HistoryUserCard/HistoryUserCard";

const styles = {
    containerUsersPaperStyle: {
        marginTop: "1%",
        marginBottom: "1%",
        maxWidth: "1200px",
        marginLeft: "15%",
        marginRight: "15%",
        padding: "0.25em",
    },
};

const HistorySkeleton = (props) => {
    return (
        <Box>
            {[{}, {}, {},].map((user, masterIndex) => {
                return (
                    <Paper key={"paperIndex-" + masterIndex} elevation={3} sx={styles.containerUsersPaperStyle}>
                        {
                            [{}, {}, {},].map((user, index) => {
                                return (
                                    <React.Fragment key={"dateIndex-" + index + "-" + masterIndex}>
                                        {index !== 0 && <Divider />}
                                        <HistoryUserCard
                                            key={"HistoryUserCardSkeletIndex-" + index + "-" + masterIndex}
                                            user={user}
                                            progress={props.progress}
                                        />
                                    </React.Fragment>
                                );
                            })
                        }
                    </Paper>
                );
            })}
        </Box>
    )

};

export default HistorySkeleton;