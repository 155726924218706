import React from 'react';
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from 'recharts';

const HasCVPieChart = (props) => {
    const colors = ['#0172BC', '#053A5C',];
    const reformData = (data) => {
        let arr = [];
        arr = [
            {
                name: "Ima uploadovan CV",
                value: data ? data.postavili : 0
            },
            {
                name: "Nema uploadovan CV",
                value: data ? data.nisuPostavili : 0
            },
        ];
        //console.log(arr);
        return arr;
    }

    return (
        <ResponsiveContainer width="100%" height="90%">
            <PieChart width={400} height={400}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={props.data ? reformData(props.data) : []}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label

                >
                    {reformData(props.data).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 2]} />
                    ))}
                </Pie>
                <Tooltip />
                <Legend />
            </PieChart>
        </ResponsiveContainer>
    );
}

export default HasCVPieChart;
