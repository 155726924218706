import AxiosInstance from '../../axiosService/axiosService';

export const favoriteUsers = (payload) => async dispatch => {
    //console.log("payload");
    //console.log(payload);
    await AxiosInstance.post(`users/newFilter`, payload).then(res => {
        //console.log(res.data);
        dispatch({ type: 'FAVORITE_USERS', payload: res.data });
        dispatch({ type: 'UPDATE_PROGRESS_LINE', payload: false });
    }).catch(err => {
        console.log('CV overview favorite users  error', err);
    });

}

