const initialState = {faq: []};

const FAQReducer =(state =initialState, action)=>{
    switch(action.type){
        case 'FETCH_FAQ': {
            return Object.assign({},state,{faq: action.payload})
        }
        default: return state;
    }
}
export default FAQReducer;