import React from "react";
import {
    Paper,
    Typography,
    Divider
  } from "@mui/material";
  

  const styles = {
    paperStyle : {
      padding: '24px 72px 24px 24px',
      marginTop: '1.5%',
      borderRadius: '10px'

        // borderColor: '#DCDCDC',
        // borderStyle: 'solid',
        // borderWidth: '2px'

  
    },
    headingStyle : {
      textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',

    },
    dateStyles: {
      textAlign: 'start',
      color: 'grey60.main'
    }
  }

const HigherEducationCard = (props) => {
    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
        <Typography sx={styles.headingStyle}>Higher education</Typography>
         {props.visokoObrazovanje.length > 0 && props.visokoObrazovanje.map((item, index) => (
           <React.Fragment key={index}>
            {(index > 0) &&
              <Divider/>
             }
         <Typography sx={{textAlign: 'start', marginTop: '1%', fontWeight: '600'}}>
           {item.fakultet + ", " + item.univerzitet + ", "+ item.smer + ", "+ item.status + ", " + item.grad + ", " + item.drzava }
           </Typography>
           <Typography sx={styles.dateStyles} variant="subtitle2" gutterBottom>{item.godinaUpisa}.</Typography>
   
           </React.Fragment>
         ))
   
         }
        </Paper>
     );
}
 
export default HigherEducationCard;