import React, { useEffect, useState } from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const CitiesPieChart = (props) => {
    const [data, setData] = useState([]);

    // const reformData = () => {
    //     setData(props.data)
    //     var list = props.data;
    //     //console.log(props.data);
    //     //console.log(data);
    //     list[0].broj = props.data[0].broj + props.data[2].broj;
    //     setData(list)
    //     //props.data?setData(data[2].broj + data[0].broj):console.log(data);
    //     return data;
    // }

    // function reformData() {
    //     console.log(props.data);
    //     console.log(list);
    //     setData(list[2].broj+ list[0].broj);
    // }

    useEffect(() => {
        if (props.data) {
            var list = props.data;
            list[0].broj = props.data[0].broj + props.data[2].broj;
            list = list.filter(function(item) {
                return item.naziv !== 'Nis';
            });
            list.forEach(city => {
              city.naziv = city.naziv.charAt(0).toUpperCase() + city.naziv.slice(1);
            });
            setData(list)
        }
    }, [props.data])

    const colors = ['#1E9BED', '#0596F4', '#0172BC', '#01578F', '#195C87', '#074F7E', '#01436E', '#053A5C', '#053A5C', '#022A45'];
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BarChart
                //layout="vertical"
                width={700}
                height={1000}
                //data={props.data}
                data={props.data ? data : []}
                //barCategoryGap={5}
                margin={{
                    top: 20,
                    right: 20,
                    left: 5,
                    bottom: 40
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="naziv"
                    type="category"
                    interval={0}
                    tickSize={20}
                    angle={25} />
                <YAxis dataKey="broj"
                    type="number"
                // interval={0}
                // tickSize={40}
                />
                <Tooltip />
                <Bar dataKey="broj" fill="black" >
                    {props.data ? props.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 10]} />
                    )) : <></>}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};

export default CitiesPieChart;