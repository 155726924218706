import React, { useEffect } from "react";
import { Paper, Typography, Button, Box } from "@mui/material";
import { fetchCharts } from "./DashboardActions";
import { connect } from "react-redux";
import history from "../../common/history";

import StudentsPerFacultyBarChart from "../../components/StudentsPerFacultyBarChart/StudentsPerFacultyBarChart";
import HasCVPieChart from "../../components/HasCVPieChart/HasCVPieChart";
import StudentPerYearPie from "../../components/StudentPerYearPie/StudentPerYearPie";
import CitiesBarChart from "../../components/CitiesBarChart/CitiesBarChart";

const styles = {
    typographyStyleHeading: {
        color: "black",
        textAlign: "start",
        marginTop: '1%',
    },
    typographyStyleDescriptionText: {
        color: "black",
        textAlign: "justify",
        marginTop: '1%',
        marginBottom: '1%',
        fontSize: '15px',
        
    },
    typographyStyleBestRegardsText: {
        color: "black",
        textAlign: "start",
    },
    welcomeTextPaperStyle: {
        padding: '24px 72px 24px 24px',
        marginTop: '2%',
        marginBottom: '1%',
        maxWidth: '1200px',
        borderRadius: '10px'
    },
    barChartBox: {
        width: '800px',
        height: '500px',
    },
    userPageWrapperBox: {
        maxWidth: '1200px',
        marginLeft: '10%',
        marginRight: '10%',
        marginTop: '1%',
        marginBottom: '1%',
    },
    flexBoxWrap: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: '1200px',
       
    },
    flexBoxColumn:{
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
    }
};

const Dashboard = (props) => {

    useEffect(() => {
        document.title = "Dashboard | CVBook";
        props.fetchCharts();
    }, []);

    return (
        <Box sx={styles.userPageWrapperBox}>
            <Paper variant="outlined" sx={styles.welcomeTextPaperStyle}>
                <Typography sx={styles.typographyStyleHeading} variant="h6" gutterBottom>Wecome to CV Book developed by BEST Nis.</Typography>
                <Typography sx={styles.typographyStyleDescriptionText} variant="body2" gutterBottom>This Web Aplication is used for browsing registerd users in our CV database. This database is part of our "JobFair" project where students from different fields of study and expertise can leave their information and CV's for possible job or internship opportunities.</Typography>
                <Typography sx={styles.typographyStyleDescriptionText} variant="body2" gutterBottom>This aplication gives your company the opportunity to browse our CV database and to find and pick the best qualified potential future employees much faster and more efficiently.</Typography>
                <Typography sx={styles.typographyStyleBestRegardsText} variant="body1" >We wish you best of luck and we hope that we will be seeing you at next JobFair.</Typography>
                <Typography sx={styles.typographyStyleBestRegardsText} variant="body1" >Best Regards, BEST Nis.</Typography>
                <Typography sx={styles.typographyStyleHeading} variant="subtitle2" gutterBottom>Current number of users who left their data <b>{props.DashboardList.stats.totalUsers ? props.DashboardList.stats.totalUsers.broj : ""}</b>.</Typography>
                <Button
                    variant={"contained"}
                    sx={{
                        borderRadius: "10px",
                        backgroundColor: "primary.main",
                        color: "white",
                    }}
                    onClick={() => history.push("/overview")}
                >
                    CV Overview
                </Button>
                {/* {console.log(props.DashboardList.stats)} */}
                {/* {JSON.stringify(props.DashboardList.stats)} */}
            </Paper>
            <Box sx={styles.flexBoxWrap}>
                <Paper variant="outlined" sx={{ padding: '1%', marginBottom: '1%', width: '760px', height: '710px',borderRadius: '10px' }}>
                <Typography variant="body2" gutterBottom={false} color="black" textAlign="middle" >Broj korisnika po fakultetu</Typography>
                    <StudentsPerFacultyBarChart data={props.DashboardList.stats ? props.DashboardList.stats.top10 : []} />
                </Paper>
                <Box sx={styles.flexBoxColumn}>
                    <Paper variant="outlined" sx={{ padding: '1%', marginLeft: '5px',  width: '434px', height: '375px',borderRadius: '10px' }}>
                    <Typography variant="body2" gutterBottom={false} color="black" textAlign="middle" >Broj uploadovanih CV-a</Typography>
                        <HasCVPieChart data={props.DashboardList.stats ? props.DashboardList.stats.cv : []} />
                    </Paper>
                    <Paper variant="outlined" sx={{ padding: '1%', marginBottom: '12px', marginTop:'2%', marginLeft: '5px', width: '434px', height: '325px',borderRadius: '10px' }}>
                        <Typography variant="body2" gutterBottom={false} color="black" textAlign="middle" >Godine studija korisnika</Typography>
                        <StudentPerYearPie data={props.DashboardList.stats ? props.DashboardList.stats.yosStats : []} />
                    </Paper>
                </Box>
                <Paper variant="outlined" sx={{ padding: '1%',  width: '1200px', height: '500px',borderRadius: '10px' }}>
                <Typography variant="body2" gutterBottom={false} color="black" textAlign="middle" >Prebivalište korisnika</Typography>
                    <CitiesBarChart data={props.DashboardList.stats ? props.DashboardList.stats.citiesStats : []} />
                </Paper>

            </Box>
        </Box >
    );
};
const mapStateToProps = (state) => {
    return { DashboardList: state.dashboardList };
};

export default connect(mapStateToProps, { fetchCharts })(Dashboard);