const initialState = { favoriteUsers: [] };

const FavoritesReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FAVORITE_USERS': {
            return Object.assign({}, state, { ...state, favoriteUsers: action.payload })
        }
        default: return state;
    }
}
export default FavoritesReducer;