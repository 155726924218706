import React from "react";
import {
    Paper,
    Typography,
    Box
  } from "@mui/material";


const styles = {
  paperStyle: {
    padding: '24px 72px 24px 24px',
    marginTop: '1.5%',
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '10px'

    // borderColor: '#DCDCDC',
    // borderStyle: 'solid',
    // borderWidth: '2px'
  },
  headingStyle : {
    textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',
  }
}

const ResidenceCard = (props) => {

    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
        <Box sx={{margin: '1%', flexGrow: 1}}>
        <Typography  sx={styles.headingStyle}>Permanent residence</Typography>
        <Typography sx={{textAlign: 'start'}}>{props.prebivaliste.drzava}</Typography>
        <Typography sx={{textAlign: 'start'}}>{props.prebivaliste.grad}</Typography>
        {props.prebivaliste.adresa.length > 0 &&
        <Typography sx={{textAlign: 'start'}}>{props.prebivaliste.adresa}</Typography>
        }
        </Box>
        <Box sx={{margin: '1%', flexGrow: 1}}>
         <Typography sx={styles.headingStyle}>Temporary residence</Typography>
        <Typography sx={{textAlign: 'start'}}>{props.boraviste.drzava}</Typography>
        <Typography sx={{textAlign: 'start'}}>{props.boraviste.grad}</Typography>
        {props.boraviste.adresa.length > 0 &&
        <Typography sx={{textAlign: 'start'}}>{props.boraviste.adresa}</Typography>
        }
       </Box>
        </Paper>
     );
}
 
export default ResidenceCard;