import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  CircularProgress,
  Tooltip,
  Typography
} from "@mui/material";
import history from "../../common/history";
import { connect } from "react-redux";
import { fetchUserById, addFavourite, removeFavourite, addToHistory } from "./UserPageActions";
import PersonalDataCard from "./UserPageComponents/PersonalDataCard";
import ResidenceCard from "./UserPageComponents/ResidenceCard";
import HighschoolEducationCard from "./UserPageComponents/HighschoolEducationCard";
import HigherEducationCard from "./UserPageComponents/HigherEducationCard";
import WorkExperienceCard from "./UserPageComponents/WorkExperienceCard";
import ProfessionalTrainingCard from "./UserPageComponents/ProfessionalTrainingCard";
import ProjectsCard from "./UserPageComponents/ProjectsCard";
import SkillsCard from "./UserPageComponents/SkillsCard";
import LanguageCard from "./UserPageComponents/LanguageCard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StarIcon from "@mui/icons-material/Star";
import cookie from "js-cookie";
import DownloadIcon from "@mui/icons-material/Download";
import JsFileDownloader from 'js-file-downloader';
import moment from 'moment';

const styles = {
  userPageWrapperBox: {
    maxWidth: "1000px",
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "1%",
    marginBottom: "4%",
  },
  iconButtonStyle: {
    "&:hover": {
      color: "primary.main",
    },
  },
  favouritesButtonActive: {
    color: "gold",
  },
  favouritesButtonInactive: {},
  downloadCVButtonStyle: {
    '&:hover': {
      color: 'primary.main'
    }
  },
  dateUpdatedStyle: {
    textAlign: 'center',
    paddingTop: '5%'
  }
};

const UserPage = (props) => {
  const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
  //const companyID = cookie.get("id");
  const userID = history.location.state.detail;
  const [progress, setProgress] = useState(true);
  const [isFavourited, setIsFavourited] = useState(false);
  
  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    if(progress===false)
    document.title = `${props.currUser.licniPodaci ? props.currUser.licniPodaci.ime : ''} ${props.currUser.licniPodaci ? props.currUser.licniPodaci.prezime : ''} | CVBook`;
  }, [progress]);

  useEffect(() => {
    props.fetchUserById(userID, companyID).then((user) => {
      setProgress(false);
    });
    props.addToHistory({userID: userID, kompanijaID: companyID});
  }, [userID]);

  useEffect(() => {
    setIsFavourited((props.currUser && props.currUser.isFavourite > 0) ? true : false);
  }, [props.currUser]);

  const handleFavourited = (isFavourited) => {
    let payload = {
      userID: userID,
      kompanijaID: companyID,
    };
    //console.log(isFavourited);
    if (!isFavourited) {
      props.addFavourite(payload).then((data) => {
        //console.log(data);
        setIsFavourited(!isFavourited);
      });
    } else {
      props.removeFavourite(payload).then((data) => {
        //console.log(data);
        setIsFavourited(!isFavourited);
      });
    }
  };

  const downloadCV = () => {
    let fileType = props.currUser.licniPodaci.cv.endsWith(".pdf") ? ".pdf" : (props.currUser.licniPodaci.cv.endsWith(".doc") ? ".doc" : ".docx")
    new JsFileDownloader({
      url: props.currUser.licniPodaci.cv,
      filename: `${props.currUser.licniPodaci.ime}_${props.currUser.licniPodaci.prezime}_CV${fileType}`,
    })
      .then(() => {
        console.log("Download succes");
      })
      .catch((error) => {
        console.log("ERROR" + error);
      });
  }

  return (
    <Box sx={styles.userPageWrapperBox}>
      {!progress && (
        <>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>
              <Tooltip title="Back">
                <IconButton
                  sx={styles.iconButtonStyle}
                  onClick={() => history.goBack()}
                >
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
            <Typography sx={styles.dateUpdatedStyle} variant="subtitle2" >{"Last time updated: "+moment(props.currUser.licniPodaci.datumAzuriranja).format("DD. MMM YYYY.").toLocaleString()}</Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              {props.currUser.licniPodaci.cv && (
                <Box>
                  <Tooltip title="Download CV">
                    <IconButton sx={styles.downloadCVButtonStyle} onClick={() => downloadCV()}>
                      <DownloadIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
              <Box>
                <Tooltip title="Favourite">
                  <IconButton
                    sx={
                      isFavourited
                        ? styles.favouritesButtonActive
                        : styles.favouritesButtonInactive
                    }
                    onClick={() => handleFavourited(isFavourited)}
                  >
                    <StarIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
          <PersonalDataCard
            licniPodaci={props.currUser.licniPodaci}
            kontakt={props.currUser.kontakt}
            email={props.currUser.email}
          />
          <ResidenceCard
            boraviste={props.currUser.boraviste}
            prebivaliste={props.currUser.prebivaliste}
          />
          {props.currUser.srednjeObrazovanje.length > 0 && (
            <HighschoolEducationCard
              srednjeObrazovanje={props.currUser.srednjeObrazovanje}
            />
          )}
          {props.currUser.visokoObrazovanje.length > 0 && (
            <HigherEducationCard
              visokoObrazovanje={props.currUser.visokoObrazovanje}
            />
          )}
          {props.currUser.iskustvo.radnoIskustvo.length > 0 && (
            <WorkExperienceCard
              radnoIskustvo={props.currUser.iskustvo.radnoIskustvo}
            />
          )}
          {props.currUser.iskustvo.strucnoUsavrsavanje.length > 0 && (
            <ProfessionalTrainingCard
              strucnoUsavrsavanje={props.currUser.iskustvo.strucnoUsavrsavanje}
            />
          )}
          {props.currUser.iskustvo.radNaProjektu.length > 0 && (
            <ProjectsCard
              radNaProjektu={props.currUser.iskustvo.radNaProjektu}
            />
          )}
          {props.currUser.iskustvo.radNaRacunaru.length > 0 && (
            <SkillsCard radNaRacunaru={props.currUser.iskustvo.radNaRacunaru} />
          )}
          {props.currUser.iskustvo.poznavanjeJezika.length > 0 && (
            <LanguageCard
              poznavanjeJezika={props.currUser.iskustvo.poznavanjeJezika}
            />
          )}
        </>
      )}
      {progress && <CircularProgress />}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return { currUser: state.userPageUser.user };
};

export default connect(mapStateToProps, {
  fetchUserById,
  addFavourite,
  removeFavourite,
  addToHistory,
})(UserPage);
