import React, { useEffect, useState} from 'react';
import { Typography, Box } from '@mui/material';
import { fetchFAQ } from './FAQActions';
import { connect } from 'react-redux';
import QACard from '../../components/QACard/QACard'

const styles = {
  root: {
    width: 'auto',
    maxWidth: '1200px',
    textAlign: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    marginLeft: '10%',
    marginRight: '10%',
    marginTop: '1%',
    marginBottom: '1%',
  },
  faqHeading: {
    color: 'black',
    marginBottom: "15px"
  }
};

const FAQ = (props) => {
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (panelNum) => (event, isExpanded) => {
    setExpanded(isExpanded ? panelNum : false);
  };

  useEffect(() => {
    document.title = "FAQ | CVBook";
    props.fetchFAQ();
  }, []);

  return (
    <Box sx={styles.root}>
      <Box sx={{maxWidth: '1200px',  marginLeft: "15%", marginRight: "15%",}}>
      <Typography variant='h2' sx={styles.faqHeading}>
        FAQ
      </Typography>
      </Box>
      <Box sx={styles.faqDiv}>
        {props.FAQList.map((QAItem, index) => {
          return (
            <QACard expanded={expanded} panelName={`panel${index}`} handleChangeExpanded={handleChangeExpanded(`panel${index}`)} QAItem={QAItem} key={index} index={index + 1} />
          )
        })}
      </Box>
    </Box>
  );
}

const mapStateToProps = (state) => {
  return { FAQList: state.faqList.faq }
};

export default connect(mapStateToProps, { fetchFAQ })(FAQ);