import React from "react";
import {
    Box,
    FormControl,
    MenuItem,
    IconButton,
    TextField,
} from "@mui/material";

import { filterUsers } from "../../pages/CvOverview/CVOverviewActions";
import { setStoreFilter } from "../../pages/CvOverview/FilterActions";
import { updateProgressLine } from "../HeaderMenu/HeaderActions";

import cookie from "js-cookie";
import { connect } from "react-redux";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const styles = {
    typographyStyleHeading: {
        color: "white",
        textAlign: "center",
        marginTop: "3%",
    },
    searchBarWrapper: {
        //maxWidth: "1200px",
        marginTop: "1%",
        marginBottom: "1%",
        marginLeft: "15%",
        marginRight: "15%",
        padding: "0em",
    },
    containerUsersPaperStyle: {
        //maxWidth: "1200px",
        borderRadius: "10px",
        marginTop: "1%",
        marginBottom: "1%",
        marginLeft: "15%",
        marginRight: "15%",
        padding: "0.25em",
    },
    orderBoxStyle: {
        display: "flex"
    }
};

const OrderByComponent = (props) => {
    const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
    //const companyID = cookie.get("id");

    const handleOrderByChange = (event) => {
        props.updateProgressLine(true);
        props.filterUsers({ filters: { ...props.FilterObject, orderBy: event.target.value }, companyID: companyID });
        props.setStoreFilter({ orderBy: event.target.value });
    };

    const handleOrderDirectionChange = () => {
        let direction;
        if (props.FilterObject.orderDirection === "ASC") { direction = "DESC"; }
        else { direction = "ASC"; }

        props.updateProgressLine(true);
        props.filterUsers({ filters: { ...props.FilterObject, orderDirection: direction }, companyID: companyID });
        props.setStoreFilter({ orderDirection: direction });
    };

    return (
        <Box sx={styles.orderBoxStyle}>
            <IconButton onClick={() => handleOrderDirectionChange()}>
                {props.FilterObject.orderDirection === "ASC" && (
                    <KeyboardArrowUpIcon sx={{ color: "black" }} />
                )
                }
                {props.FilterObject.orderDirection === "DESC" && (
                    <KeyboardArrowDownIcon sx={{ color: "black" }} />
                )
                }
            </IconButton>

            <Box sx={{ width: 140 }}>
                <FormControl fullWidth>
                    {/* <InputLabel id="order-by-label">Order by</InputLabel> */}
                    <TextField
                        select
                        color="primary"
                        size="small"
                        //labelId="order-by-label"
                        id="order-by-select"
                        value={props.FilterObject.orderBy}
                        label="Order by"
                        onChange={handleOrderByChange}
                    >
                        <MenuItem key="MenuItem-orderBy-0" value={""}><em>Select one</em></MenuItem>
                        <MenuItem key="MenuItem-orderBy-dateUpdated" value={"dateUpdated"}>Date Updated</MenuItem>
                        <MenuItem key="MenuItem-orderBy-firstName" value={"firstName"}>First Name</MenuItem>
                        <MenuItem key="MenuItem-orderBy-lastName" value={"lastName"}>Last Name</MenuItem>
                        <MenuItem key="MenuItem-orderBy-faculty" value={"faculty"}>Faculty</MenuItem>
                        <MenuItem key="MenuItem-orderBy-yearOfStudy" value={"yearOfStudy"}>Year Of Study</MenuItem>
                        <MenuItem key="MenuItem-orderBy-grade" value={"grade"}>Grade</MenuItem>
                    </TextField>
                </FormControl>
            </Box>
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        FilterObject: state.filterObject
    };
};

export default connect(mapStateToProps, {
    filterUsers,
    setStoreFilter,
    updateProgressLine,
})(OrderByComponent);
