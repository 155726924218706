const initialState = {user: null};

const UserPageReducer =(state =initialState, action)=>{
    switch(action.type){
        case 'FETCH_USER_BY_ID': {
            return Object.assign({},state,{user: action.payload})
        }
        default: return state;
    }
}
export default UserPageReducer;