import React, { useEffect, useState } from "react";
import {
    Button,
    TextField,
    Box,
    InputAdornment,
    Fab,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    MenuItem,
} from "@mui/material";
import { filterUsers, getFilters } from "../../pages/CvOverview/CVOverviewActions";
import { setStoreFilter, resetStoreFilter } from "../../pages/CvOverview/FilterActions";
import { updateProgressLine } from "../HeaderMenu/HeaderActions";

import cookie from "js-cookie";
import { connect } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import MultipleSelect from "../MultipleSelect/MultipleSelect";

const styles = {
    searchBar: {
        marginRight: "0.5%",
        marginLeft: "1%",
    },
    searchBarTyping: {
        marginRight: "0.5%",
        marginLeft: "1%",
        backgroundColor: '#CCCCCC',
    },
    searchButtonStyle: {},
    filtersOpenButton: {
        fontWeight: "bold",
    },
    filterContainerBox: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        margin: "7px",
    },
    filterAccordionStyles: {
        marginTop: "1%",
    },
    filterButtonsStyle: {
        marginTop: "2%",
    },
};

const FilterAcordion = (props) => {
    const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
    //const companyID = cookie.get("id");
    const [openFilters, setOpenFilters] = useState(false);
    const [searchText, setsearchText] = useState(props.FilterObject.searchText);
    const [typing, setTyping] = useState(false);

    useEffect(() => {
        let delayDebounceFn = setTimeout(() => {
            setTyping(true);
            //console.log(searchText);
            props.setStoreFilter({ searchText: searchText });
        }, 500)

        return () => { clearTimeout(delayDebounceFn); setTyping(false); }
    }, [searchText])

    const [CV, setCV] = useState(props.FilterObject.cv);
    const [Favourite, setFavourite] = useState(props.FilterObject.favourite);

    const [YOS, setYOS] = useState(props.FilterObject.yos);
    const [isErrorYOS, setErrorYOS] = useState(false);
    const [helperTextYOS, setHelperTextYOS] = useState('');

    const [Grade, setGrade] = useState(props.FilterObject.grade);
    const [isErrorGrade, setErrorGrade] = useState(false);
    const [helperTextGrade, setHelperTextGrade] = useState('');

    useEffect(() => {
        props.getFilters()
    }, [])
    const runFilterdSerach = () => {
        props.updateProgressLine(true);
        props.filterUsers({ filters: props.FilterObject, companyID: companyID })
    };
    const runResetSerach = () => {
        setCV(false);
        setFavourite(false);
        setYOS("");
        setGrade("");
        setsearchText("");
        props.updateProgressLine(true);
        setTimeout(() => {props.resetStoreFilter(companyID);}, 1);
    };
    const handleOpenFilters = () => {
        setOpenFilters(!openFilters);
    };
    const handleFilterCVChange = () => {
        setCV(!CV);
        setTimeout(() => {props.setStoreFilter({ cv: !CV });}, 1)
    };
    const handleFilterFavouriteChange = () => {
        setFavourite(!Favourite);
        setTimeout(() => {props.setStoreFilter({ favourite: !Favourite });}, 1)
    };
    const handleFilterYOSChange = (event) => {
        if (event.target.value > 10 || event.target.value < 0) {
            setErrorYOS(true);
            setHelperTextYOS("Out of bounds 0-10");
        } else {
            setYOS(event.target.value);
            setHelperTextYOS("");
            setErrorYOS(false);
            setTimeout(() => {props.setStoreFilter({ yos: event.target.value });}, 1)
        }
    };
    const handleFilterGradeChange = (event) => {
        if (event.target.value > 10 || event.target.value < 0) {
            setErrorGrade(true);
            setHelperTextGrade("Out of bounds 6-10");
        } else {
            setGrade(event.target.value);
            setHelperTextGrade("");
            setErrorGrade(false);
            setTimeout(() => {props.setStoreFilter({ grade: event.target.value });}, 1)
        }
    };
    const handleFilterSearchTextChange = (event) => {
        setsearchText(event.target.value);
    };
    const handlerEnterPress = (event) => {
        if (event.key === "Enter")
            runFilterdSerach();
    };


    return (
        <Accordion
            expanded={openFilters}
            sx={styles.filterAccordionStyles}
        >
            <AccordionSummary aria-controls="filter-content" id="filter-header">
                <Button
                    size="large"
                    onClick={handleOpenFilters}
                    sx={styles.filtersOpenButton}
                    startIcon={<TuneIcon />}
                >
                    FILTERS
                </Button>
                <TextField
                    size="small"
                    fullWidth
                    label="First name or last name"
                    id="search"
                    sx={typing ? styles.searchBar : styles.searchBarTyping}
                    value={searchText}
                    onChange={handleFilterSearchTextChange}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"></InputAdornment>,
                    }}
                    onKeyPress={(e) => handlerEnterPress(e)}
                />
                <Box>
                    <Fab
                        size="small"
                        color="primary"
                        sx={styles.searchButtonStyle}
                        onClick={runFilterdSerach}
                    >
                        <SearchIcon />
                    </Fab>
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                <FormControl sx={{ zIndex: 'mobileStepper' }} component="fieldset"  >
                    <FormGroup>
                        <Box sx={styles.filterContainerBox}>
                            <MultipleSelect
                                titleText="Faculty"
                                optionArray={props.FilterOptions ? props.FilterOptions.fakulteti : []}
                            />
                        </Box>
                        <Box sx={styles.filterContainerBox}> {/*, marginTop: "20px", marginLeft: "10px", zIndex: 999  */}
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    size="small"
                                    //labelId="demo-yos-select-label"
                                    id="demo-yos-select"
                                    sx={{ width: "300px", zIndex: 0 }}
                                    label="Year of study"
                                    value={YOS}
                                    onChange={handleFilterYOSChange}
                                    error={isErrorYOS}
                                    helperText={helperTextYOS}
                                >
                                    <MenuItem key="MenuItem-yos-0" value={""}><em>Select one</em></MenuItem>
                                    <MenuItem key="MenuItem-yos-1" value={1}>1</MenuItem>
                                    <MenuItem key="MenuItem-yos-2" value={2}>2</MenuItem>
                                    <MenuItem key="MenuItem-yos-3" value={3}>3</MenuItem>
                                    <MenuItem key="MenuItem-yos-4" value={4}>4</MenuItem>
                                    <MenuItem key="MenuItem-yos-5" value={5}>5</MenuItem>
                                    <MenuItem key="MenuItem-yos-6" value={6}>6</MenuItem>
                                </TextField>
                            </FormControl>
                        </Box>
                        <Box sx={styles.filterContainerBox}>
                            <FormControl fullWidth>
                                <TextField
                                    select
                                    size="small"
                                    //labelId="demo-grade-select-label"
                                    id="demo-grade-select"
                                    sx={{ width: "300px", zIndex: 0 }}
                                    label="Grade average"
                                    value={Grade}
                                    onChange={handleFilterGradeChange}
                                    error={isErrorGrade}
                                    helperText={helperTextGrade}
                                >
                                    <MenuItem key="MenuItem-grade-0" value={""}><em>Select one</em></MenuItem>
                                    <MenuItem key="MenuItem-grade-6" value={6}>6</MenuItem>
                                    <MenuItem key="MenuItem-grade-7" value={7}>7</MenuItem>
                                    <MenuItem key="MenuItem-grade-8" value={8}>8</MenuItem>
                                    <MenuItem key="MenuItem-grade-9" value={9}>9</MenuItem>
                                </TextField>
                            </FormControl>
                        </Box>
                        <Box sx={styles.filterContainerBox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={CV}
                                        onChange={handleFilterCVChange}
                                        name="hasCV" />
                                }
                                label="Has CV"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={Favourite}
                                        onChange={handleFilterFavouriteChange}
                                        name="ifFavorited" />
                                }
                                label="Is Favorited"
                            />

                        </Box>
                    </FormGroup>
                </FormControl>
                <Box sx={styles.filterButtonsStyle}>
                    <Button
                        sx={{ marginRight: "0.5%" }}
                        variant="outlined"
                        onClick={runResetSerach}
                    >
                        Reset
                    </Button>
                    <Button
                        onClick={runFilterdSerach}
                        sx={{ marginLeft: "0.5%" }}
                        variant="contained"
                    >
                        Submit
                    </Button>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};


const mapStateToProps = (state) => {
    return {
        FilterOptions: state.cvOverviewUserList.filterOptions,
        FilterObject: state.filterObject
    };
};

export default connect(mapStateToProps, {
    filterUsers,
    getFilters,
    setStoreFilter,
    resetStoreFilter,
    updateProgressLine,
})(FilterAcordion);
