import AxiosInstance from '../../axiosService/axiosService';

export const fetchHistory = (kompanijaID) => async dispatch => {
    await AxiosInstance.post(`/kompanija/history/get`, { kompanijaID }).then(res => {
        //console.log(res.data);
        dispatch({ type: 'FETCH_HISTORY', payload: res.data });
        dispatch({ type: 'UPDATE_PROGRESS_LINE', payload: false });
    }).catch(err => {
        console.log('History page fetch history error', err);
    });
}

