import {combineReducers, createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk';
import HeaderReducer from '../components/HeaderMenu/HeaderReducer';
import FAQReducer from '../pages/FAQ/FAQReducer';
import CVOverviewReducer from '../pages/CvOverview/CVOverviewReducer';
import FilterReducer from '../pages/CvOverview/FilterReducer';
import UserPageReducer from '../pages/UserPage/UserPageReducer';
import DashboardReducer from '../pages/Dashboard/DashboardReducer';
import HistoryReducer from '../pages/History/HistoryReducer';
import FavoritesReducer from '../pages/Favorites/FavoritesReducer';

const reducers = combineReducers({
    headerObject: HeaderReducer,
    faqList: FAQReducer,
    cvOverviewUserList: CVOverviewReducer,
    userPageUser: UserPageReducer,
    historyList: HistoryReducer,
    dashboardList: DashboardReducer,
    favoriteList: FavoritesReducer,
    filterObject: FilterReducer,
})

export const store = createStore(reducers, compose(applyMiddleware(/*logger,*/ thunk), window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : f => f));