import React from "react";
import {
  Divider,
    Paper,
    Typography,
  } from "@mui/material";

  const styles = {
    paperStyle : {
      padding: '24px 72px 24px 24px',
      marginTop: '1.5%',
      borderRadius: '10px'
        // borderColor: '#DCDCDC',
        // borderStyle: 'solid',
        // borderWidth: '2px'

    },
    headingStyle : {
      textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',
    },
    dateStyles: {
      textAlign: 'start',
      color: 'grey60.main'
    }

  }

const HighschoolEducationCard = (props) => {
    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
        <Typography sx={styles.headingStyle}>High school education</Typography>
         {props.srednjeObrazovanje.length > 0 && props.srednjeObrazovanje.map((item, index) => (
           <React.Fragment key={index}>
            {(index > 0) &&
              <Divider/>
             }
         <Typography sx={{textAlign: 'start', fontWeight: '600'}}>
           {item.tip + ", " + item.naziv + ", "+ item.smer + ", " + item.grad + ", " + item.drzava}
           </Typography>
           <Typography sx={styles.dateStyles} variant="subtitle2" gutterBottom>{item.godinaZavrsetka}.</Typography>

   
           </React.Fragment>
         ))
   
         }
        </Paper>
     );
}
 
export default HighschoolEducationCard;