import axios from 'axios'
import cookie from 'js-cookie'
//import {store} from '../store/store';

class AxiosInstance {

  constructor(config) {
    this.instance = axios.create({baseURL: process.env.REACT_APP_MAIN_URL});

    this.instance.interceptors.response.use(response => {

      const {data} = response;

      if (data && data.status === 409) {
        
      }

      return response
    }, 
    error => {
      const {response }= error;
      if (response && response.status === 401) {
        //store.dispatch({type: ActionTypes.OPEN})
      }

      if (response) throw response;
      
      throw error;
    })

    this.instance.interceptors.request.use(config => {

      // if (config.method !== "GET") {
      //   const token = sessionStorage.getItem('token');
      //   if (token === 'guest') {
      //     throw 'You are logged in as a guest, to continue log in with an existing account or create a new one'
      //   }
      // }

      if (config.url !== '/users/auth' ) {
        const token = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).token:undefined;
        config.headers.Authorization = `Bearer ${token}`
      }

      return config
    })

    // this.instance.interceptors.request.use(request => {
      
    // })

  }
}

export default new AxiosInstance({}).instance;