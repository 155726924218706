import React, { useState, useEffect } from "react";
import { Typography, Box, IconButton, Tooltip, } from "@mui/material";
import LetterAvatar from "lettered-avatar";
import JsFileDownloader from 'js-file-downloader';

import DownloadIcon from "@mui/icons-material/Download";
import StarIcon from '@mui/icons-material/Star';
import LaunchIcon from '@mui/icons-material/Launch';

import HistoryUserCardSkeleton from "./HistoryUserCardSkeleton";

const styles = {
  containerStyle: {
    margin: "1%",
    display: "flex",
    justifyContent: 'space-between',
    padding: '0.25em'
  },
  contentBox: {
    display: "flex"
  },
  avatarWrapperBox: {
    margin: '0.5em'
  },
  nameAndSurnameBox: {
    textAlign: 'start',
    paddingTop: '0.7em'
  },
  nameTypography: {
    fontWeight: 'bold',
  },
  actionBox: {
    display: 'flex',
    paddingTop: '1em',
    marginRight: '1em'
  },
  dividerStyle: {
    marginTop: "0.5em",
    marginBottom: "0.5em",
    marginLeft: "1.5em",
    marginRight: "1em",
  },
  middleContentBox: {
    display: "flex",
    flexGrow: 1,
    justifyContent: 'right',
    marginRight: "35%",
    alignItems: 'center',
    textAlign: 'center',
    paddingTop: '0.7em',
  },
  favouritesButtonActive: {
    color: "gold",
  },
  favouritesButtonInactive: {},
  downloadCVButtonStyle: {
    '&:hover': {
      color: 'primary.main'
    }
  }
};



const HistoryUserCard = (props) => {
  const [isFavourited, setIsFavourited] = useState(
    props.user.kompanija_favourites &&
      props.user.kompanija_favourites.length > 0
      ? true
      : false
  );

  useEffect(() => {
    if (props.user)
      setIsFavourited(props.user.isFavourite &&
        props.user.isFavourite > 0
        ? true
        : false)
  }, [props.user]);

  const downloadCV = () => {
    let fileType = props.user.cv.endsWith(".pdf") ? ".pdf" : (props.user.cv.endsWith(".doc") ? ".doc" : ".docx")
    new JsFileDownloader({
      url: props.user.cv,
      filename: `${props.user.ime}_${props.user.prezime}_CV${fileType}`,
    })
      .then(() => {
        console.log("Download succes");
      })
      .catch((error) => {
        console.log("ERROR" + error);
      });
  }

  return (
    <div>
      {!props.progress &&
        <Box sx={styles.containerStyle} key={props.user.userID}>
          <Box sx={styles.contentBox}>
            <Box sx={styles.avatarWrapperBox}>
              <LetterAvatar
                name={props.user ? (props.user.ime +
                  " " +
                  props.user.prezime) : ""}
                imgSrc={
                  props.user && props.user.profilnaSlika
                    ? props.user.profilnaSlika //"https://dev.jobfairnis.rs/users/returnpicture/?file=" +
                    : null
                }
                options={{
                  twoLetter: true,
                  size: 52,
                }}
              />
            </Box>
            <Box sx={styles.nameAndSurnameBox}>
              <Typography sx={styles.nameTypography} variant="body1">{props.user.ime + " " + props.user.prezime}</Typography>
              <Typography variant="body2">{props.user.fakultet}</Typography>
            </Box>
          </Box>
          <Box sx={styles.middleContentBox}>
            <Typography variant="body2">{props.user.visitedT}</Typography>
          </Box>
          <Box sx={styles.actionBox}>
            {!props.user.cv.endsWith("null") && (
              <Box>
                <Tooltip title="Download CV">
                  <IconButton sx={styles.downloadCVButtonStyle} onClick={() => downloadCV()}>  {/* disabled={props.user.cv?false:true} */}
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {props.user.cv.endsWith("null") && (
              <Box sx={{ width: "40px" }}>
                <IconButton sx={styles.downloadCVButtonStyle}>
                </IconButton>
              </Box>
            )}
            <Box>
              <Tooltip title="Open">
                <IconButton onClick={props.handleClick}>
                  <LaunchIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box>
              <Tooltip title="Favourite">
                <IconButton
                  sx={
                    isFavourited
                      ? styles.favouritesButtonActive
                      : styles.favouritesButtonInactive
                  }
                  onClick={() => {
                    setIsFavourited(!isFavourited);
                    props.handleFavourited(!isFavourited, props.user.userID);
                  }}
                >
                  <StarIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      }
      {props.progress && (<HistoryUserCardSkeleton user={props.user} />)}
    </div>
  );
};

export default HistoryUserCard;