import React from "react";
import { Box, IconButton, Tooltip, Skeleton } from "@mui/material";

import DownloadIcon from "@mui/icons-material/Download";
import LaunchIcon from "@mui/icons-material/Launch";
import StarIcon from "@mui/icons-material/Star";

const styles = {
    containerStyle: {
        margin: "1%",
        display: "flex",
        justifyContent: "space-between",
        padding: "0.1em",
    },
    contentBox: {
        display: "flex",
        flexGrow: 1,
        "&:hover": {
            cursor: "pointer",
        },
    },
    avatarWrapperBox: {
        margin: "0.5em",
    },
    nameAndSurnameBox: {
        textAlign: "start",
        paddingTop: "0.7em",
    },
    actionBox: {
        display: "flex",
        paddingTop: "1em",
        marginRight: "1em",
    },
    dividerStyle: {
        marginTop: "0.5em",
        marginBottom: "0.5em",
        marginLeft: "1.5em",
        marginRight: "1em",
    },
};

const CVOverviewUserCardSkeleton = (props) => {
    return (
        <Box sx={styles.containerStyle} key={props.user.userID}>
            <Box sx={styles.contentBox}>
                <Box sx={styles.avatarWrapperBox}>
                    <Skeleton
                        animation="wave"
                        variant="circular"
                        width={52}
                        height={52}
                    />
                </Box>
                <Box sx={styles.nameAndSurnameBox}>
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="150px"
                        style={{ marginBottom: 6 }}
                    />
                    <Skeleton
                        animation="wave"
                        height={10}
                        width="130px"
                        style={{ marginBottom: 6 }}
                    />
                </Box>
            </Box>
            <Box sx={styles.actionBox}>
                <Box>
                    <Tooltip title="Download CV">
                        <IconButton >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Open">
                        <IconButton>
                            <LaunchIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box>
                    <Tooltip title="Favourite">
                        <IconButton>
                            <StarIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    )

};

export default CVOverviewUserCardSkeleton;