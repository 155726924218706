import HelpIcon from '@material-ui/icons/Help';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ViewListIcon from '@mui/icons-material/ViewList';
//import BookmarkIcon from '@mui/icons-material/Bookmark';
import StarIcon from "@mui/icons-material/Star";
import HistoryIcon from '@mui/icons-material/History';


export const menuItems = {
    root: [
        {
            item: {
                icon: (<DashboardIcon />),
                allowed: true,
                name: 'Dashboard',
                text: 'Menu.Dashboard',
                beginGroup: false,
                itemData: '/',
            },
            children: []
        },
        {
            item: {
                icon: (<ViewListIcon />),
                allowed: true,
                name: 'CV Overview',
                text: 'Menu.CvOverview',
                beginGroup: false,
                itemData: '/overview',
            },
            children: []
        },
        {
            item: {
                icon: (<HistoryIcon />),
                allowed: true,
                name: 'History',
                text: 'Menu.History',
                beginGroup: false,
                itemData: '/history',
            },
            children: []
        },
        {
            item: {
                icon: (<StarIcon />),
                allowed: true,
                name: 'Favorites',
                text: 'Menu.Favorites',
                beginGroup: false,
                itemData: '/favorites',
            },
            children: []
        },
        {
            item: {
                icon: (<HelpIcon />),
                allowed: true,
                name: 'FAQ',
                text: 'Menu.FAQ',
                beginGroup: false,
                itemData: '/faq',
            },
            children: []
        },
    ]
}