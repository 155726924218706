import React from "react";
import {
    Box,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
} from "@mui/material";

import { filterUsers } from "../../pages/CvOverview/CVOverviewActions";
import { setStoreFilter } from "../../pages/CvOverview/FilterActions";
import { updateProgressLine } from "../HeaderMenu/HeaderActions";

import cookie from "js-cookie";
import { connect } from "react-redux";

const PageSizeCompnent = (props) => {
  const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
    //const companyID = cookie.get("id");

    const handlePageSizeChange = (event) => {
        let changes = {stepPagination: event.target.value, pagePagination: 1};

        props.updateProgressLine(true);
        props.filterUsers({ filters: { ...props.FilterObject, ...changes }, companyID: companyID });
        props.setStoreFilter(changes);
      };

    return (
        <Box sx={{ width: 100 }}>
        <FormControl fullWidth>
          <InputLabel id="page-size-label">Page size</InputLabel>
          <Select
            color="primary"
            size="small"
            labelId="page-size-label"
            id="page-size-select"
            value={props.FilterObject.stepPagination}
            label="Page size"
            onChange={handlePageSizeChange}
          >
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
            <MenuItem value={200}>200</MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
};


const mapStateToProps = (state) => {
    return {
        FilterObject: state.filterObject
    };
};

export default connect(mapStateToProps, {
    filterUsers,
    setStoreFilter,
    updateProgressLine,
})(PageSizeCompnent);
