import cookie from 'js-cookie'
const initialState = {
  searchText: "",
  yos: "",
  grade: "",
  faculty: [], //["Mašinski fakultet","Elektronski fakultet"],
  cv: false,
  favourite: false,
  pagePagination: 1,
  stepPagination: 50,
  orderBy: "",
  orderDirection: "DESC",
};

const cookieFilter = cookie.get('filter')?JSON.parse(cookie.get('filter')):undefined;

const FilterReducer = (state = cookieFilter?cookieFilter:initialState, action) => {
  switch (action.type) {
    case "UPDATE_FILTER": {
      let content = { ...state, ...action.payload };
      // console.log("newFilter");
      // console.log(content);
      cookie.set('filter',  JSON.stringify(content), { domain: process.env.REACT_APP_COOKIE_DOMAIN })
      return Object.assign({}, state, content);
    }
    case "RESET_FILTER": {
      let content = { ...initialState };
      cookie.set('filter', JSON.stringify(content), { domain: process.env.REACT_APP_COOKIE_DOMAIN })
      return Object.assign({}, state, content);
    }
    default:
      return state;
  }
};
export default FilterReducer;
