const initialState = { stats: [] };

const DashboardReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_CHARTS': {
            return Object.assign({}, state, { ...state, stats: action.payload })
        }
        default: return state;
    }
}
export default DashboardReducer;