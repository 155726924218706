import AxiosInstance from '../../axiosService/axiosService';

const initialState = {
    searchText: "",
    yos: "",
    grade: "",
    faculty: [], //["Mašinski fakultet","Elektronski fakultet"],
    cv: false,
    favourite: false,
    pagePagination: 1,
    stepPagination: 50,
    orderBy: "",
    orderDirection: "DESC",
  };

export const setStoreFilter = (filters) => async dispatch => {
    dispatch({ type: 'UPDATE_FILTER', payload: filters });
}

export const resetStoreFilter = (ID) => async dispatch => {
    dispatch({ type: 'RESET_FILTER', payload: "" });

    await AxiosInstance.post(`users/newFilter`, { filters: initialState, companyID: ID }).then(res => {
        //console.log(res.data);
        dispatch({ type: 'FILTER_USERS', payload: res.data });
        dispatch({ type: 'UPDATE_PROGRESS_LINE', payload: false });
    }).catch(err => {
        console.log('CV overview filter users  error', err);
    });
}