import React from "react";
import {
    Paper,
    Divider,
} from "@mui/material";
import CVOverviewUserCard from "../../components/CVOverviewUserCard/CVOverviewUserCard";

const styles = {
    containerUsersPaperStyle: {
        marginTop: "1%",
        marginBottom: "1%",
        maxWidth: "1200px",
        marginLeft: "15%",
        marginRight: "15%",
        padding: "0.25em",
    },
};

const FavoritesSkeleton = (props) => {
    return (
        <Paper sx={styles.containerUsersPaperStyle}>
            {[{}, {}, {}, {}, {}, {}].map((user, index) => {
                return (
                    <React.Fragment key={"ReactFragmentSkeletonIndex-" + index}>
                        {index !== 0 && <Divider />}
                        <CVOverviewUserCard user={user} progress={props.progress} />
                    </React.Fragment>
                );
            })}
        </Paper>
    )

};

export default FavoritesSkeleton;