import React, { useEffect, useState } from "react";
import {
    Paper,
    Typography,
    Divider,
    Box,
} from "@mui/material";
import { addFavourite, removeFavourite } from "../CvOverview/CVOverviewActions"
import { fetchHistory } from "./HistoryActions";
import { updateProgressLine } from "../../components/HeaderMenu/HeaderActions";

import cookie from "js-cookie";
import { connect } from "react-redux";
import history from "../../common/history";
import HistoryUserCard from "../../components/HistoryUserCard/HistoryUserCard";
import HistorySkeleton from "./HistorySkeleton";

const styles = {
    typographyStyleHeading: {
        color: "black",
        textAlign: "center",
        marginTop: "1%",
        marginLeft: "15%",
        marginRight: "15%",
    },
    containerUsersPaperStyle: {
        marginTop: "1%",
        marginBottom: "1%",
        maxWidth: "1200px",
        marginLeft: "15%",
        marginRight: "15%",
        padding: "0.25em",
    },
};

const History = (props) => {
    const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
    //const companyID = cookie.get("id");
    const [progress, setProgress] = useState(true);

    useEffect(() => {
        props.updateProgressLine(true);
        props.fetchHistory(companyID)
            .then(() => setProgress(false));
    }, []);

    useEffect(() => {
        if (progress === false)
            document.title = `History | CVBook`;
    }, [progress]);

    const handleFavourited = (isFavourited, userID) => {
        let payload = {
            userID: userID,
            kompanijaID: companyID,
        };
        //console.log(isFavourited);
        if (isFavourited) {
            props.addFavourite(payload).then((data) => {
                //console.log(data);
            });
        } else {
            props.removeFavourite(payload).then((data) => {
                //console.log(data);
            });
        }
        props.updateProgressLine(true);
        setTimeout(()=>{props.fetchHistory(companyID)
            .then(() => setProgress(false));},10);
    };

    return (
        <Box>
            <Box sx={{maxWidth: '1200px',  marginLeft: "15%", marginRight: "15%",}}>
            <Typography variant="h2" sx={styles.typographyStyleHeading}>History</Typography>
            </Box>
            {!progress &&
                <Box>
                    {props.HistoryList &&
                        props.HistoryList.map((user, index) => {
                            return (
                                <Paper key={"paperIndex-" + index} elevation={3} sx={styles.containerUsersPaperStyle}>
                                    <Typography variant="h6" sx={styles.typographyStyleHeading}>{user.date}</Typography>
                                    {user.history.map((user, index) => {
                                        return (
                                            <React.Fragment key={"dateIndex-" + index + "-" + user.userID}>
                                                {index !== 0 && <Divider />}
                                                <HistoryUserCard
                                                    key={"HistorzUserCardIndex-" + index + "-" + user.userID}
                                                    handleFavourited={(isFavourited, userID) =>
                                                        handleFavourited(isFavourited, userID)
                                                    }
                                                    handleClick={() => {
                                                        //console.log(user.userID);
                                                        history.push(`/user/${user.userID}`, { detail: user.userID });
                                                    }}
                                                    user={user}
                                                    progress={progress}
                                                />
                                            </React.Fragment>
                                        );
                                    })}
                                </Paper>
                            );
                        })}
                </Box>
            }
            {progress && (<HistorySkeleton progress={progress}/>)}
        </Box >
    );
};

const mapStateToProps = (state) => {
    return { HistoryList: state.historyList.history };
};

export default connect(mapStateToProps, {
    fetchHistory,
    addFavourite,
    removeFavourite,
    updateProgressLine,
})(History);