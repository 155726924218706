import React from "react";
import { Paper, Typography, Divider } from "@mui/material";
import moment from "moment";


const styles = {
  paperStyle : {
    padding: '24px 72px 24px 24px',
    marginTop: '1.5%',
    borderRadius: '10px'

      // borderColor: '#DCDCDC',
      // borderStyle: 'solid',
      // borderWidth: '2px'


  },
  headingStyle : {
    textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',
     
  },
  dateStyles: {
    textAlign: "start",
    color: "grey60.main",
  },
}

const WorkExperienceCard = (props) => {
  return (
    <Paper variant="outlined" sx={styles.paperStyle}>
      <Typography sx={styles.headingStyle}>
        Work experience
      </Typography>
      {props.radnoIskustvo &&
        props.radnoIskustvo.map((item, index) => (
          <React.Fragment key={index}>
            {index > 0 && <Divider/>}
            <Typography sx={{ textAlign: "start" ,marginTop:"1%", fontWeight: '600'}}>
              {item.kompanija +
                ", " +
                item.funkcija}
            </Typography>
           <Typography sx={styles.dateStyles} variant="subtitle2" gutterBottom>{moment(item.datumPocetka).format("MMM YYYY.").toLocaleString() + " - "+ (moment(item.datumZavrsetka).isValid() ? moment(item.datumZavrsetka).format("MMM YYYY.").toLocaleString() : 'Current')}</Typography>
            <Typography variant="subtitle2" sx={{ textAlign: "start" }}>
              {item.opis}
            </Typography>
          </React.Fragment>
        ))}
    </Paper>
  );
};

export default WorkExperienceCard;
