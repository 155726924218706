import React from "react";
import {
    Pagination
} from "@mui/material";

import { filterUsers } from "../../pages/CvOverview/CVOverviewActions";
import { setStoreFilter } from "../../pages/CvOverview/FilterActions";
import { updateProgressLine } from "../HeaderMenu/HeaderActions";

import cookie from "js-cookie";
import { connect } from "react-redux";

const PaginationComponent = (props) => {
    const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
    //const companyID = cookie.get("id");

    const handlePageChange = (event, value) => {
        let changes = { pagePagination: value };
        props.updateProgressLine(true);
        props.filterUsers({ filters: { ...props.FilterObject, ...changes }, companyID: companyID });
        props.setStoreFilter(changes);
    };

    function calculateCount() {
        return props.userCount ? Math.ceil(props.userCount / props.FilterObject.stepPagination) : 1
    };

    return (
        <Pagination
            count={calculateCount()}
            color="primary"
            page={props.FilterObject.pagePagination}
            onChange={handlePageChange}
            sx={{marginTop: "4px", marginBottom: "1px",}}
        />
    );
};

const mapStateToProps = (state) => {
    return {
        FilterObject: state.filterObject
    };
};

export default connect(mapStateToProps, {
    filterUsers,
    setStoreFilter,
    updateProgressLine,
})(PaginationComponent);
