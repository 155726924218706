import React from "react";
import {
    Paper,
    Typography,
  } from "@mui/material";


  const styles = {
    paperStyle : {
      padding: '24px 72px 24px 24px',
      marginTop: '1.5%',
      borderRadius: '10px'

        // borderColor: '#DCDCDC',
        // borderStyle: 'solid',
        // borderWidth: '2px'

  
    },
    headingStyle : {
      textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',
    }
  }

const SkillsCard = (props) => {
    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
        <Typography sx={styles.headingStyle}>Skills</Typography>
         {props.radNaRacunaru && props.radNaRacunaru.map((item, index) => (
           <React.Fragment key={index}>
         <Typography sx={{textAlign: 'start'}}>
           {item.naziv + ", " + item.nivo}
           </Typography>
           </React.Fragment>
         ))
   
         }
        </Paper>
     );
}
 
export default SkillsCard;