import React, { useEffect, useState } from "react";
import {
  Checkbox,
  ListItemText,
} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { connect } from "react-redux";
import { setStoreFilter } from "../../pages/CvOverview/FilterActions";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultipleSelect = (props) => {
  const [personName, setPersonName] = useState(props.FilterObject.faculty);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
    
  };

  useEffect(() => {
    if (props.FilterObject.faculty !== personName)
      props.setStoreFilter({ faculty: personName });
  }, [personName]);

  useEffect(() => {
    if (props.FilterObject.faculty.length === 0)
      setPersonName(props.FilterObject.faculty);
  }, [props.FilterObject.faculty]);

  const calculateKey = (name) => {
    //console.log(name.fakultetID);
  return "MenuItem-MultipleSelect-"+name.fakultetID;
  }

  return (
    <FormControl sx={{ width: "300px", zIndex: 'tooltip' }} fullWidth>  {/*, marginTop: "20px", marginLeft: "10px", zIndex: 1600 sx={{zIndex: 'tooltip'}}*/}
      <InputLabel id="demo-multiple-checkbox-label">Faculty</InputLabel>
      <Select
        //select
        size='small'
        //labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        //label='Faculty'
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput label="Faculty" />}
        renderValue={(selected) => selected.join(', ')}
        MenuProps={MenuProps}
      >
        {props.optionArray? props.optionArray.map((name) => (
          <MenuItem key={calculateKey(name)} value={name.naziv}>
            <Checkbox key={"Checkbox-"+name.fakultetID} checked={personName.indexOf(name.naziv) > -1} />
            <ListItemText key={"ListItemText-"+name.fakultetID} primary={name.naziv} />
          </MenuItem>
        )):<></>}
      </Select>
    </FormControl>
  );
}

const mapStateToProps = (state) => {
  return {
    FilterObject: state.filterObject
  };
};

export default connect(mapStateToProps, { setStoreFilter })(MultipleSelect);