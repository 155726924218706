import React from "react";
import {
    Paper,
    Typography,
  } from "@mui/material";


  const styles = {
    paperStyle : {
      padding: '24px 72px 24px 24px',
      marginTop: '1.5%',
      borderRadius: '10px'

        // borderColor: '#DCDCDC',
        // borderStyle: 'solid',
        // borderWidth: '2px'

  
    },
    headingStyle : {
      textAlign: 'start',
      color: 'primary.main',
      fontSize: '20px',
    }
  }

const LanguageCard = (props) => {
    return ( 
        <Paper variant="outlined" sx={styles.paperStyle}>
        <Typography sx={styles.headingStyle}>Foreign language</Typography>
         {props.poznavanjeJezika && props.poznavanjeJezika.map((item, index) => (
           <React.Fragment key={index}>
         <Typography sx={{textAlign: 'start', fontWeight: '600'}}>
           {item.jezik }
           </Typography>
           <Typography variant='subtitle2' sx={{textAlign: 'start'}}>{"Reading level:  " + item.nivoCitanja}</Typography>
           <Typography variant='subtitle2' sx={{textAlign: 'start'}}>{"Writting level: "+item.nivoPisanja}</Typography>
           <Typography variant='subtitle2' sx={{textAlign: 'start'}}>{"Level of understanding: "+item.nivoRazumevanja}</Typography>
           <Typography variant='subtitle2' sx={{textAlign: 'start'}}>{"Seech level: "+item.nivoGovora}</Typography>
           </React.Fragment>
         ))
   
         }
        </Paper>
     );
}
 
export default LanguageCard;