import React, { useEffect, useState } from "react";
import {
  Paper,
  Box,
  Divider,
} from "@mui/material";
import {
  filterUsers,
  addFavourite,
  removeFavourite,
} from "./CVOverviewActions";
import { setStoreFilter } from "./FilterActions";
import { updateProgressLine } from "../../components/HeaderMenu/HeaderActions";

import history from "../../common/history";
import cookie from "js-cookie";
import { connect } from "react-redux";

import CVOverviewUserCard from "../../components/CVOverviewUserCard/CVOverviewUserCard";
import FilterAcordion from "../../components/FilterAcordion/FilterAcordion";
import OrderByComponent from "../../components/OrderByComponent/OrderByComponent";
import PageSizeCompnent from "../../components/PageSizeCompnent/PageSizeCompnent";
import PaginationComponent from "../../components/PaginationComponent/PaginationComponent";
import CVOverviewSkeleton from "./CVOverviewSkeleton";

const styles = {
  searchBarWrapper: {
    //maxWidth: "1200px",
    marginTop: "1%",
    marginBottom: "1%",
    marginLeft: "15%",
    marginRight: "15%",
    padding: "0em",
  },
  containerUsersPaperStyle: {
    //maxWidth: "1200px",
    borderRadius: "10px",
    marginTop: "1%",
    marginBottom: "1%",
    marginLeft: "15%",
    marginRight: "15%",
    padding: "0.25em",
  },
  topPaginationBoxWrapper: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "15%",
    marginRight: "15%",
    marginTop: "1%",
    marginBottom: "1%",
    alignItem: "center",
  },
  bottomPaginationBoxWrapper: {
    display: "flex",
    justifyContent: "center",
    marginTop: "1%",
    marginBottom: "1%",
  },
};

const CvOverview = (props) => {
  const companyID = cookie.get('CV-Book')?JSON.parse(cookie.get('CV-Book')).id:undefined;
  //const companyID = cookie.get("id");
  const [progress, setProgress] = useState(true);
  const [title, setTitle] = useState("CVBook");

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    setTitle(`CvOverview | CVBook`);
    props.updateProgressLine(true);
    props.filterUsers({ filters: props.FilterObject, companyID: companyID })
      .then((data) => {
        setProgress(false);
      })
  }, [])

  const handleFavourited = (isFavourited, userID) => {
    let payload = {
      userID: userID,
      kompanijaID: companyID,
    };
    //console.log(isFavourited);
    if (isFavourited) {
      props.addFavourite(payload).then((data) => {
        //console.log(data);
      });
    } else {
      props.removeFavourite(payload).then((data) => {
        //console.log(data);
      });
    }
  };

  return (
    <Box>

      {/* Filter box Acordion that drops down */}
      <Box variant="outlined" square sx={styles.searchBarWrapper}>
        <FilterAcordion />
      </Box>

      {/* Three compnents inbetweeners */}
      <Box sx={styles.topPaginationBoxWrapper}>
        <PageSizeCompnent />
        <PaginationComponent userCount={props.FilterdUsersList.broj} />
        <OrderByComponent />
      </Box>

      {!progress && (
        <>
          {/* Users listed */}
          <Paper variant="outlined" sx={styles.containerUsersPaperStyle}>
            {props.FilterdUsersList &&
              props.FilterdUsersList.lista.map((user, index) => {
                //console.log(user);
                return (
                  <React.Fragment key={"ReactFragmentIndex-" + index}>
                    {index !== 0 && <Divider />}
                    <CVOverviewUserCard
                      middleColum={props.FilterObject.orderBy}
                      handleFavourited={(isFavourited, userID) => handleFavourited(isFavourited, userID)}
                      handleClick={() => {
                        //console.log(user.userID);
                        history.push(`/user/${user.userID}`, {
                          detail: user.userID,
                        });
                      }}
                      user={user}
                    />
                  </React.Fragment>
                );
              })}
          </Paper>

          {/* Bottom pagination */}
          <Box sx={styles.bottomPaginationBoxWrapper}>
            <PaginationComponent userCount={props.FilterdUsersList.broj} />
          </Box>

        </>
      )}
      {progress && (<CVOverviewSkeleton progress={progress} />)}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    FilterdUsersList: state.cvOverviewUserList.filterdUsers,
    FilterObject: state.filterObject
  };
};

export default connect(mapStateToProps, {
  filterUsers,
  addFavourite,
  removeFavourite,
  setStoreFilter,
  updateProgressLine,
})(CvOverview);
