const HeaderReducer = ( state = {progressLine: false}, action ) => {
    switch(action.type) {
        case 'UPDATE_PROGRESS_LINE': {
            return { ...state, progressLine: action.payload };
        }
        default:
            return state;
    }
}

export default HeaderReducer;