import React from "react";
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { filterUsers } from "../../pages/CvOverview/CVOverviewActions";
import { setStoreFilter } from "../../pages/CvOverview/FilterActions";
import { updateProgressLine } from "../HeaderMenu/HeaderActions";

import history from "../../common/history";
import { connect } from "react-redux";

const StudentsPerFacultyBarChart = (props) => {
    const colors = ['#1E9BED', '#0596F4', '#0172BC', '#01578F', '#195C87', '#074F7E', '#01436E', '#053A5C', '#053A5C', '#022A45'];

    const handleBarClick = (entry) => {
        //console.log(entry);
        let faks = [entry.naziv];
        //console.log({ ...props.FilterObject, faculty: faks });
        props.updateProgressLine(true);
        props.setStoreFilter({ faculty: faks });
        history.push("/overview");
    };

    return (
        <ResponsiveContainer width="100%" height="95%">
            <BarChart
                layout="vertical"
                width={700}
                height={1000}
                data={props.data}
                barCategoryGap={5}
                margin={{
                    top: 0,
                    right: 20,
                    left: 0,
                    bottom: 0
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="broj"
                    type="number"
                    // interval={0}
                    // tickSize={40}
                    angle={0} />
                <YAxis dataKey="naziv"
                    type="category"
                    interval={0}
                    tickSize={20}
                    width = {200}
                    angle={0}
                    //label={{ position: 'left', viewBox}}
                    style={{ fontSize: 15 }}
                />
                <Tooltip />
                <Bar dataKey="broj" fill="black" >
                    {props.data ? props.data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 10]} onClick={() => { handleBarClick(entry) }} />
                    )) : <></>}
                </Bar>

            </BarChart>
        </ResponsiveContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        FilterObject: state.filterObject
    };
};

export default connect(mapStateToProps, {
    filterUsers,
    setStoreFilter,
    updateProgressLine,
})(StudentsPerFacultyBarChart);