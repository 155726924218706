import React from "react";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArticleIcon from '@mui/icons-material/Article';
import {
    Paper,
    Typography,
    Box,
    IconButton,
    Tooltip,
  } from "@mui/material";
import LetterAvatar from "lettered-avatar";
import moment from "moment";




const styles = {
    paperStyle : {
        display: 'flex', 
        padding: '24px 72px 24px 24px',
        borderRadius: '10px'

        // borderColor: '#DCDCDC',
        // borderStyle: 'solid',
        // borderWidth: '2px'

  
    },
    avatarWrapper: {
      width: '160px',
      height: '160px',
      backgroundColor: 'grey30.main',
      borderRadius: '50%',
      padding: '4px',
      marginRight: '4%'

    },
    linkedInButton: {
      padding: '0px',
      '&:hover': {
        color: 'primary.main'
      },
   
    },
    cvButtonStyle: {
      width: '40px', 
      height: '40px',
      padding: '0px',
      '&:hover': {
        color: 'primary.main'
      }
  }
}



const PersonalDataCard = (props) => {
    return ( 
        <Paper sx={styles.paperStyle} variant="outlined">
        <Box sx={styles.avatarWrapper} >
         <LetterAvatar
             className = "UserAvatar"
             name={props.licniPodaci.ime + " " + props.licniPodaci.prezime}
             imgSrc={props.licniPodaci.profilnaSlika ? props.licniPodaci.profilnaSlika : null}
             options={{
               twoLetter: true,
               size: 152,
               imgWidth: 152, 
               imgHeight: 152 
             }}
           />
         </Box>
         <Box sx={{paddingTop: '1%', paddingBottom: '1%'}}>
        <Typography sx={{fontWeight: 'bold', textAlign: 'start'}} color="primary.main" variant="h5">{props.licniPodaci.ime + " " + props.licniPodaci.prezime /*+ " " + `(${props.licniPodaci.imeRoditelja})`*/}</Typography>
        <Typography variant="subtitle2" sx={{textAlign: 'start'}}>{moment(props.licniPodaci.datumRodjenja).format("DD. MMM YYYY.").toLocaleString()}</Typography>
         <Typography variant="subtitle1" sx={{textAlign: 'start', fontWeight: 'bold', color: 'primary.main'}}>Contact:</Typography>
         <Typography variant="subtitle2" sx={{textAlign: 'start',fontWeight: 'bold'}}>{props.kontakt.telefon}</Typography>
         <Typography variant="subtitle2" sx={{textAlign: 'start', fontWeight: 'bold'}}>{props.email}</Typography>
         <Box sx={{display: 'flex'}}>
         {props.kontakt.linkedIn.length > 0 &&
           <IconButton sx={styles.linkedInButton} onClick={() => window.open(props.kontakt.linkedIn, '_blank')}>
           <LinkedInIcon/>
           </IconButton>
         }
         </Box>
        </Box>
        {props.licniPodaci.cv &&
        <Box  sx={{marginLeft: '5%', paddingTop: '6%', paddingBottom: '6%' }}>
           <Tooltip title="View CV">
            <IconButton sx={styles.cvButtonStyle} onClick={() => window.open(props.licniPodaci.cv, '_blank')}>
           <ArticleIcon sx={{width: '32px', height: '32px'}}/>
           </IconButton>
           </Tooltip>
        </Box>
        }
      </Paper>
     );
}
 
export default PersonalDataCard;