const initialState = { history: [] };

const HistoryReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'FETCH_HISTORY': {
            return Object.assign({}, state, { ...state, history: action.payload })
        }
        default: return state;
    }
}
export default HistoryReducer;